export const CONTRIBUTERS=[
    {
        name:'Anit Singh',
        email:'anitsingh0095@gmail.com',
        branch:'Computer Science',
        year:'2019',
        score:'+50',
    },
    {
        name:'Kashish Kashyap',
        email:'kashishk0508@gmail.com',
        branch:'Computer Science',
        year:'2020',
        score:'+40',
    },
    {
        name:'Agam Jain',
        email:'aagamjain573@gmail.com',
        branch:'Computer Science',
        year:'2020',
        score:'+40',
    },
    {
        name:'Akash Madheshiya',
        email:'akmadhesiya90@gmail',
        branch:'Computer Science',
        year:'2019',
        score:'+20',
    },  
    {
        name:'Shobhit Singh',
        email:'imshobhit2003@gmail.com',
        branch:'Computer Science',
        year:'2021',
        score:'+20',
    },

]