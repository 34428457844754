export const TABS = [
    {
      label: 'Home',
      value: '/home',
    },
    {
      label: 'Papers',
      value: '/papers',
    },
    {
      label: 'Notes',
      value: '/Notes',
    },
    
    {
      label: 'Syllabus',
      value: '/syllabus',
    },
    {
      label: 'Clubs',
      value: '/clubs',
    },
    {
      label: 'Contributers',
      value: '/contributers',
    },
    {
      label: 'Contact Us',
      value: '/contact',
    },
  ]
  export const TABS2=[
    {
      label: 'Contribute',
      value: '/contributemsg',
    },
  ];